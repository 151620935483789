@import '../variables.scss';

.loginContainer {
    height: 100vh;
    width: auto;
    background-color: $login-background-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        height: 100px;
        width: auto;
        margin-bottom: 50px;
    }

    form {
        display: flex;
        flex-direction: column;
        max-width: 500px;

        @media (min-width: $small) {
            width: 50%;
        }

        @media (min-width: $medium) {
            width: 500px;
        }

        @media (min-width: $large) {
            width: 500px;
        }



        input {
            color: white;
            height: 50px;
            background: transparent;
            border: none;
            font-size: 30px;
            border-bottom: 1px solid $primary-color;
            outline: none;
            margin-bottom: 20px;
            padding-left: 10px;
        }

        button {
            margin-top: 25px;
            height: 75px;
            background-color: $primary-color;
            color: white;
            border: none;
            outline: none;
            font-size: 20px;
        }

        .signUp {
            background-color: #EFCA48;
        }
    }
}