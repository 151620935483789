@import '../../variables.scss';

.galaManagementContainer {
    .galaSelector {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0;
        background-color: white;
        padding: 0 10px;
        border-radius: 8px;
    }

    table {
        width: 100%;
        margin-top: 20px;

        tr>th,
        tr>td {
            text-align: center;
        }

        tr>td {
            padding: 10px 0;
            border-bottom: 1px dotted $inactive-color;
        }
    }

    .info {
        width: calc(60% - 10px);
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .buttonContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        height: 50px;
        margin-top: 24px;
        margin-bottom: 40px;

        .buttonBox {
            // float: right;
            display: flex;
            align-items: center;

            button {
                border: 1px solid $primary-color;
                outline: none;
                background-color: white;
                box-sizing: border-box;
                border-radius: 20px;
                height: 50px;
                width: 150px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: $primary-color;
                cursor: pointer;
                transition: all 0.25s;
            }

            button:not(:first-child) {
                margin-left: 20px;
            }

            .active {
                background: $primary-color;
                color: white;
            }

            .cancel {
                margin-right: 8px;
            }

            .confirm {
                background-color: $primary-color;
                color: white;
            }

            button:disabled {
                background: $primary-color;
                color: white;
                border: none;
            }
        }
    }

    // .row {
    //     display: flex;

    //     .info {
    //         width: 10000;
    //     }
    // }

    .galaScreenWrapper {

        padding: 20px;
        background: white;
        border-radius: 8px;
    }
}