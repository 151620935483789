@import '../../../variables.scss';

.galaDonationModal {
    width: 500px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;

    header {
        position: relative;

        margin-bottom: 25px;

        h2 {
            font-weight: bold;
            font-size: 24px;
            margin: 0;
        }

        button {
            position: absolute;
            right: 0;
            top: 0px;
            background: $primary-color;
            color: white;
            border-radius: 8px;
            border: none;
            padding: 5px 10px;
            margin: 0;
            cursor: pointer;
        }

    }

    .infoWrapper {
        display: flex;
        flex-direction: column;

        .info {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dotted black;
            padding: 5px 0;

            p:first-of-type {
                margin-right: 50px;
            }

            address {
                text-align: right;
            }
        }

        .contactInfo:last-child {
            border: none;
        }
    }

    .seatingWrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 3px solid black;

        .seat {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dotted black;
            padding: 5px 0;

            p:first-of-type {
                margin-right: 50px;
            }

            address {
                text-align: right;
            }
        }

        .contactInfo:last-child {
            border: none;
        }
    }

    h2 {
        margin-top: 25px;
    }

    .editContainer {
        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            border-bottom: 1px dotted $inactive-color;
        }

        input[type='text'],
        input[type='number'],
        select {
            width: 100%;
            border: 1px solid #dcdcdc;
            background-color: white;
            border-radius: 8px;
            height: 50px;
            padding: 0 10px;
            appearance: none;
        }

        textarea {
            width: 100%;
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            height: 150px;
            padding: 10px 10px;
        }

        .attendeeSelector>div {
            border-radius: 8px;
        }
    }
}

.optionStatModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}