@import '../../../../variables.scss';

.CorpReqMaterialsModal {
    width: 1200px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;

    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 24px;
    }

    .request {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        margin-top: 20px;
        padding: 20px 20px;
        display: flex;
        // flex-direction: row;;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin: 0;
            flex-grow: 1;
            flex-basis: 0;
            font-size: 18px;
        }

        p {
            text-align: center;
            flex-grow: 1;
            flex-basis: 0;
        }

        .actions {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;

            button {
                flex-grow: 1;
                flex-basis: 0;
                height: 40px;
                border: none;
                outline: none;
                color: white;
                cursor: pointer;
            }

            .cancel {
                background: $attention-color;
            }

            .send {
                background: #28d45f;
            }

            button:disabled {
                cursor: auto;
                opacity: 50%;
            }
        }
    }
}

.CorpReqMaterialsModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}