@import '../../../../../../variables.scss';

.combos {
    width: 100%;
    margin-bottom: 25px;
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .addCombo {
        width: 100%;
        margin-top: 8px;
        height: 50px;
        background-color: $primary-color;
        color: white;
        outline: none;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        font-size: 24px;
        cursor: pointer;
    }
}