@import '../../../variables.scss';

.ordersStatsContainer {
    display: flex;
    .ordersCol:not(:last-child) {
        margin-right: 8px;
    }
    .ordersCol {
        // flex-grow: 1;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.5px solid #DCDCDC;
        border-radius: 16px;
        padding: 170px 0;
        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 8px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
        }
    }
}