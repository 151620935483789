@import '../../../../variables.scss';

.addBarContainer {
    cursor: pointer;
    width: 100%;
    border: 1px dashed $inactive-color;
    height: 175px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    h2 {
        font-style: normal;
        color: $primary-color;
        font-size: 40px;
    }
    p {
        font-weight: 600;
        font-size: 16px;
    }
}