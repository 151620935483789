@import '../../variables.scss';

.headerContainer{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    z-index: 99;

    transition: left $animation-time;
    .headerTop {
        padding: 0 $header-padding;
        display: flex;
        align-items: center;
        height: $header-top-height;
        background-color: $header-top-background;
    }
    .headerBot{
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        height: $header-bot-height;
        background-color: $header-bot-background;
        padding: 0 $dashboard-padding-x;
        display: flex;
        .headerStoreTabs{
            cursor: pointer;
            margin-top: 8px;
            width: 250px; // tab width
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px 8px 0 0;
            color: #7191A6;
        }
        .headerStoreTabs.active {
            color: white;
            background: #3d5666;
        }
    }

}

.headerOpen {
    left: $tabbar-width;
}