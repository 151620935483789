@import '../../variables.scss';

.transactionContainer {
    .transList {
        .transaction {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 8px 0;
            background-color: white;
            padding: 0 10px;
            border-radius: 8px;
        }
    }
}