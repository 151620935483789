@import '../../../../../variables.scss';

.itemCardContainer {
    width: calc(50% - 12.5px);
    background-color: white;
    margin: 12.5px 0;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 10px;

    height: 175px;
    display: flex;
    justify-content: space-between;

    .itemImg {
        width: 40%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
    }

    .info {
        width: calc(60% - 10px);
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
        .price {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .itemFnContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .slider {
            position: absolute;
            top: 0;
            right: 0px;
            height: 20px;
            width: 40px;
            background-color: $primary-color;
            border-radius: 10px;
            display: flex;
            align-items: center;
            transition: all 0.25s;
            cursor: pointer;
            .knob {
                transition: all 0.25s;
                height: 22px;
                width: 22px;
                position: absolute;
                right: 0;
                background-color: white;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
                border-radius: 50%;
            }
        }
        .slider.inactive {
            background-color: $inactive-color;
            .knob {
                right: calc(100% - 22px);
            }
        }
        .edit {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius:  50%;
            background-color: #F3F7FA;
            cursor: pointer;
        }
    }
}