@import '../../variables.scss';

.spinnerContainer {
    height: calc(100vh - #{$header-height});
    display: flex;
    justify-content: center;
    align-items: center;

    // z-index: 9999999;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // background-color: white;
}