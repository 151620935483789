@import '../../variables.scss';

.menuContainer {
    .menuCard {
        height: 150px;
        width: 100%;
        border-radius: 16px;
        cursor: pointer;
        background-color: white;
        margin-top: 15px;
    }   
    .newMenu{
        background-color: transparent !important;
        border: 1px dashed #7D7D7D;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;
        .plus{
            color: $primary-color;
            font-size: 75px;
        }
    }
}