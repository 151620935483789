@import '../../../../../variables.scss';

.storeMetaCardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    border-radius: 16px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

    button {
        height: 40px;
        padding: 0 40px;
        background: $primary-color;
        color: white;
        border-radius: 8px;
        border: none;
        cursor: pointer;
    }
}