@import '../../../../variables.scss';

.addEditEventModal {
    width: 500px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .inputBox {
        margin-top: 25px;

        h3 {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }

    .timeit {

        // .timeit-wrapper-0-1-1 
        display: flex;
        flex-direction: column;
        justify-content: center;

        .timeit-control-0-1-2 {
            flex-grow: 1;
        }

        .timeit-selected-0-1-9 {
            color: $primary-color;
        }

        .timeit-control__time-0-1-3 {
            svg {
                fill: $primary-color;
            }
        }
    }

    .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;

        button {
            height: 40px;
            width: 150px;
            outline: none;
            border: none;
            border-radius: 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            background: white;
            cursor: pointer;
            user-select: none;
        }

        .cancel {
            margin-right: 10px;
            border: 1px solid $primary-color;
            color: $primary-color;
        }

        .confirm {
            background-color: $primary-color;
            color: white;
        }
    }
}

.addEditEventModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}