@import '../../../variables.scss';

.staffStatsContainer {
    display: flex;
    flex-direction: column;
    .staffWrapper:not(:first-child) {
        margin-top: 20px;
    }
    .staffWrapper {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #DCDCDC;
        padding: 32px;
        border-radius: 16px;
        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: $primary-color;
            margin-bottom: 20px;
            margin: 0;
            flex-grow: 1;
            flex-basis: 0;
        }
        .staffInnerCol {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3, p {
                margin: 0;
            }
            h3 {
                margin-bottom: 10px; 
            }
        }
        .staffInnerCol:last-of-type {
            align-items: flex-end;
        }
    }
}