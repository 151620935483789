@import '../../../variables.scss';

.allStoresContainer {
    .back {
        height: 30px;
        width: 30px;
        background: white;
        border: 2px solid $primary-color;
        outline: none;
        border-radius: 8px;
        color: $primary-color;
        font-weight: 700;
        margin-right: 25px;
    }

    .allStores {
        margin-top: 32px;
        padding: 30px 35px;
        background-color: white;
        border-radius: 16px;
    }

    .viewSelection {
        display: flex;
        flex-direction: column;
        padding: 50px;

        button:not(:first-child) {
            margin-top: 25px;
        }

        button {
            height: 100px;
            border: 2px solid $primary-color;
            border-radius: 16px;
            background: $primary-color;
            color: white;
            font-weight: 700;
            font-size: 24px;
        }

    }
}