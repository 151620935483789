//Body
$body-background-color: #E5E5E5;
//Media Queries
$small: 375px;
$medium: 768px;
$large: 1366px;

$primary-color: #4fa5f4;
$attention-color: #e45f5f;
$base-color: white;
$background-color: #f2f2f2;
$inactive-color: #b1b1b1;
$animation-time: 0.75s;

//Login
$login-background-color: black;

//Tabbar
$tabbar-width: 300px;
$tabbar-background-color: #1C2D37;
$tabbar-header-info-color: #7191A6;
$tabbar-link-active-color: #2F4858;

//Header
$header-top-height: 75px;
$header-top-background: white;
$header-bot-height: 50px;
$header-bot-background: #2F4858;
$header-height: calc(#{$header-top-height + $header-bot-height});
$header-padding: 40px;

//Dashboard
$dashboard-padding-x: 180px;
$dashboard-padding-y: 30px;
