@import '../../../../../../../variables.scss';

.optionElemCardContainer {
    width: 250px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    margin: 10px;
    position: relative;

    h3 {
        width: 100%;
        border-bottom: 1px solid $inactive-color;
        margin-bottom: 8px;
    }

    h4 {
        margin-bottom: 8px;
    }

    .inputBox {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 8px;
        height: 24px;
    }

    .optionElemPriceKey {
        display: flex;
        height: 24px;
        margin-bottom: 8px;

        p {
            width: 50%;
        }

        input {
            width: 50%;
            border: 1px solid $inactive-color;
            border-radius: 8px;
            padding-left: 10px;
        }
    }

    .ingrList {
        .ingr {
            margin-bottom: 5px;
            display: flex;

            p {
                margin: 0;
                font-weight: normal;
                width: 50%;
                padding-left: 10px;
                border: 1px solid $inactive-color;
            }

            .left {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-right: none;
            }

            .right {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }

        button {
            margin-top: 10px;
            width: 100%;
            border: 1px solid $primary-color;
            border-radius: 8px;
            background-color: transparent;
            color: $primary-color;
            height: 30px;
        }
    }

    .removeOptionElem {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        background-color: $attention-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        cursor: pointer;
        border: none;
        outline: none;
    }
}