@import '../../variables.scss';

.staffManagementContainer {
    box-sizing: border-box;
    padding-right: 75px;

    .staffManagement {
        margin-top: 32px;
        padding: 30px 35px;
        background-color: white;
        border-radius: 16px;

        .addStaff {
            margin-top: 20px;

            .addStaffBtn {
                width: 100%;
                background-color: $primary-color;
                height: 50px;
                border-radius: 8px;
                color: white;
                font-size: 24px;
                font-weight: bold;
                border: none;
                cursor: pointer;
                margin-bottom: 50px;
            }

            .addStaffInputWrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                padding: 20px;
                padding-right: 50px;
                margin-right: 75px;
                border-radius: 16px;
                position: relative;
                margin-bottom: 50px;

                input {
                    width: 60%;
                    height: 50px;
                    font-size: 24px;
                    border: none;
                    border-bottom: 1px solid black;
                    outline: none;
                }

                // button:not(:first-child) {
                //     margin-left: 2.5px;
                // }

                button {
                    margin-left: 20px;
                    flex-grow: 1;
                    height: 50px;
                    padding: 5px 0;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    border-radius: 8px;
                    color: white;
                    font-weight: 600;
                    cursor: pointer;
                }

                .confirm {
                    background: #82C282;
                }

                .cancel {
                    background: $attention-color;
                }
            }
        }

        .pastStaff {
            border-top: 1px solid $inactive-color;
            padding-top: 50px;
        }
    }
}