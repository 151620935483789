@import '../../../variables.scss';

.galaTicketSaleContainer {

    .paymentStatus {
        margin-bottom: 20px;

        .row {
            display: flex;
            margin-top: 5px;

            button {
                flex-grow: 1;
                flex-basis: 0;
                background: white;
                outline: none;
                // border-radius: 8px;
                border: 2px solid $primary-color;
                padding: 8px;
                color: $primary-color;
                cursor: pointer;
                transition: all 0.25s;
                font-size: 15px;
            }

            button:first-of-type {
                border-top-left-radius: 8px;
                border: 2px solid $primary-color;
                border-bottom-left-radius: 8px;
            }

            button:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .active {
                background: $primary-color;
                color: white;
            }
        }
    }

    .headerRow {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        border-bottom: 2px solid $primary-color;

        div {
            flex-grow: 1;
            flex-basis: 0;
            margin: 0 2px;
        }
    }

    .ticketSaleRow {
        display: flex;
        flex-direction: row;
        font-weight: normal;
        font-size: 12px;
        text-align: center;
        border-bottom: 1px dotted black;
        padding: 5px 0;
        align-items: center;

        // .small {
        //     width: 10%;
        // }

        // .mid {
        //     width: 15%;
        // }

        // .name {
        //     width: 20%;
        // }

        // .contact {
        //     width: 25%;
        // }
        div {
            flex-grow: 1;
            flex-basis: 0;
            margin: 0 2px;

            button {
                width: 100%;
                background: $primary-color;
                color: white;
                border-radius: 8px;
                outline: none;
                border: none;
                cursor: pointer;
                padding: 5px 0;
                // margin: 0 5px;
            }
        }
    }

}