@import '../../../variables.scss';

.transactionStatsContainer {
    display: flex;
    flex-wrap: wrap;
    // .transCol:not(:last-child) {
    //     margin-right: 8px;
    // }
    .transCol {
        margin: 5px;
    }
    .transCol {
        // flex-grow: 1;
        width: calc(25% - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.5px solid #DCDCDC;
        border-radius: 16px;
        padding: 32px 0;
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: $primary-color;
            margin-bottom: 20px;
        }
        .transStat {
            padding: 20px 0;
            text-align: center;
            h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                margin-bottom: 8px;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
            }
        }

    }
}