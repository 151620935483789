@import '../../../../variables.scss';

.itemStatModal {
    width: 1000px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;
    header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemStats {
            display: flex;
            align-items: center;
            h2 {
                color: $primary-color;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: 0em;
                text-align: left;
            }
            h3 {
                margin: 0 20px;
            }
        }

        .chartSwitcher {
            button:not(:last-child) {
                margin-right: 10px;
            }
            button {
                padding: 10px 20px;
                border-radius: 20px;
                outline: none;
                border: none;
                background: none;
                cursor: pointer;
            }
            .active {
                background: #EEF6FE;
                color: $primary-color;
            }
        }
    }
}

.itemStatModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}