@import '../../../variables.scss';

.corpRawMaterialsContainer {
    header {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;

        // button {
        //     position: absolute;
        //     left: -50px;
        //     width: 40px;
        //     height: 40px;
        //     outline: none;
        //     border: none;
        //     border-radius: 8px;
        //     color: $primary-color;
        //     font-size: 24px;
        //     font-weight: bold;
        //     border: 1px solid $primary-color;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     cursor: pointer;
        // }

        .requestRawMats {
            position: absolute;
            right: 0;
            height: 35px;
            padding: 0 20px;
            background: $primary-color;
            color: white;
            outline: none;
            border: none;
            border-radius: 8px;
            cursor: pointer;
        }
    }

    .taxContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}