@import '../../../variables.scss';

.floorplanModal {
    background-color: black;
    height: 100%;
    width: 100%;
    outline: none;
    position: relative;
    color: white;

    .saveFloorplan {
        position: absolute;
        bottom: 25px;
        left: 25px;
        z-index: 9999;
        height: 100px;
        width: 100px;
        border-radius: 16px;
        background-color: $primary-color;
        font-weight: 600;
        cursor: pointer;
        border: none;
        outline: none;
    }
    .stageWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .editMode {
        width: 200px;
        height: 100px;
        // border-radius: 16px;
        // border-top-left-radius: 16px;
        // border-top-right-radius: 16px;
        border-radius: 16px;
        background-color: #292929;
        position: absolute;
        z-index: 999;
        bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: width, height, top, bottom, 0.25s;
        font-weight: 600;
        font-size: 20px;
        color: $primary-color;
        cursor: pointer;
    }
    @keyframes appear {
        from {opacity: 0;}
        to {opacity: 100%;}
    }
    .editModeButtons {
        display: flex;
        width: 100%;
        height: calc(100% - 10px);
        padding: 10px;
        justify-content: space-between;
        button {
            background-color: #343434;
            border-radius: 16px;
            outline: none;
            border: none;
            margin: 10px;
            flex-grow: 1;
            font-weight: 600;
            font-size: 20px;
            color: white;
            animation: appear 0.25s;
            cursor: pointer;
        }
        .exitButton {
            color: $primary-color;
        }
    }

    .active {
        width: 750px;
        height: 150px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        bottom: 0;
        cursor: default;
        color: white;
    }
}
  
.floorplanOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 99; // Greater than 10 because Konva uses z-index: 10
    display: flex;
    justify-content: center;
    align-items: center;
}