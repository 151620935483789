@import '../../../../../../../variables.scss';

.comboElemCardContainer {
    width: 250px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    margin: 10px;
    position: relative;
    h3 {
        width: 100%;
        border-bottom: 1px solid $inactive-color;
        margin-bottom: 8px;
    }
    .inputBox {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 8px;
        height: 24px;
    }
    .comboElemPriceKey {
        display: flex;
        height: 24px;
        margin-bottom: 8px;
        p {
            width: 50%;
        }
        input {
            width: 50%;
            border: 1px solid $inactive-color;
            border-radius: 8px;
            padding-left: 10px;
        }
    }
    .removeComboElem {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        background-color: $attention-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        cursor: pointer;
        border: none;
        outline: none;
    }
}