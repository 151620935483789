@import '../../../../variables.scss';

.requestMaterialsModal {
    width: 1200px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;

    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 24px;
    }

    hr {
        margin-top: 20px;
    }

    .request {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        margin-top: 20px;
        padding: 20px 20px;
        display: flex;
        // flex-direction: row;;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin: 0;
            flex-grow: 1;
            flex-basis: 0;
            font-size: 18px;
        }

        p {
            text-align: center;
            flex-grow: 1;
            flex-basis: 0;
        }

        .actions {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;

            button {
                flex-grow: 1;
                flex-basis: 0;
                height: 40px;
                border: none;
                outline: none;
                color: white;
                cursor: pointer;
            }

            .cancel {
                background: $attention-color;
            }

            .send {
                background: #28d45f;
            }

            button:disabled {
                cursor: auto;
                opacity: 50%;
            }
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        align-content: center;

        .space {
            width: 16px;
            height: 1px;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .ingrReport {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .inputBox {
        width: 100%;
        margin-bottom: 16px;

        h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #2F4858;
        }

        input,
        select {
            margin-top: 8px;
            width: 100%;
            border: 1px solid #B1B1B1;
            box-sizing: border-box;
            border-radius: 8px;
            outline: none;
            height: 50px;
            padding: 0 10px;
            background-color: white;
        }

        textarea {
            margin-top: 8px;
            width: 100%;
            border: 1px solid #B1B1B1;
            box-sizing: border-box;
            border-radius: 8px;
            outline: none;
            height: 100px;
            padding: 10px;
        }
    }

    .buttonContainer {
        width: 100%;
        position: relative;
        height: 50px;
        margin-top: 24px;

        .buttonBox {
            float: right;

            button {
                border: 1px solid $primary-color;
                outline: none;
                background-color: white;
                box-sizing: border-box;
                border-radius: 20px;
                height: 50px;
                width: 150px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: $primary-color;
                cursor: pointer;
            }

            .cancel {
                margin-right: 8px;
            }

            .confirm {
                background-color: $primary-color;
                color: white;
            }
        }
    }

    // .infoButton {
    //     background: $primary-color;
    //     color: white;
    //     outline: none;
    //     border: none;
    //     border-radius: 16px;
    //     height: 30px;
    //     width: 160px;
    //     cursor: pointer;
    // }

    .viewAll {
        height: 50px;
        color: white;
        background: $primary-color;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .viewAllActive {
        background: $inactive-color;
        cursor: auto;
    }

    .reportStats {
        flex-grow: 1;
        margin-left: 20px;

        .viewSwitcher {
            display: flex;
            width: 100%;

            button {
                height: 50px;
                border: 2px solid $primary-color;
                color: $primary-color;
                background-color: transparent;
                padding: 0 10px;
                flex-grow: 1;
                flex-basis: 0;
                cursor: pointer;
            }

            .viewStocks {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-right: none;
            }

            .viewAdj {
                border-right: none;
            }

            .viewUsages {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .active {
                background: $primary-color;
                color: white;
            }
        }

        h3 {
            margin-top: 20px;
        }
    }

    table {
        width: 100%;
        margin-top: 20px;

        tr>th,
        tr>td {
            text-align: center;
        }

        tr>td {
            padding: 10px 0;
            border-bottom: 1px dotted $inactive-color;
        }
    }
}

.requestMaterialsModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}