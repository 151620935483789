@import '../../../../../../variables.scss';

.subItems {
    margin-bottom: 25px;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .ingrElems {
        margin-top: 25px;

        .ingr {
            display: flex;
            width: 100%;
            position: relative;
            margin-bottom: 10px;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }

            .ingrName {
                display: flex;
                align-items: center;
                width: 50%;
                height: 50px;
                border: 1px solid $inactive-color;
                border-right: none;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;

            }

            .ingrVal {
                display: flex;
                align-items: center;
                width: 50%;
                border: 1px solid $inactive-color;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .ingrName,
            .ingrVal {
                padding-left: 20px;
            }

            p {
                // width: 20px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
            }
        }
    }

    .subItem {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding: 5px;

        // background-color: rgba(190, 190, 190, 0.2);
        // border-radius: 8px;
        select {
            width: calc(100% - 30px);
            height: 50px;
            border: 1px solid $inactive-color;
            background-color: white;
            border-radius: 8px;
            appearance: none;
            padding: 0 10px;
        }

        .removeSubItem {
            width: 20px;
            height: 20px;
            background-color: #e45f5f;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            position: absolute;
            right: 7.5px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            outline: none;
            border: none;
        }
    }

    .addSubItem {
        width: 100%;
        margin-top: 8px;
        height: 50px;
        background-color: $primary-color;
        color: white;
        outline: none;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        font-size: 24px;
        cursor: pointer;
    }
}