@import '../../../variables.scss';

.shiftCalendarContainer {
    .staffLegend {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 15px;

        .staff {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            font-weight: 900;
            align-items: center;

            .staffColor {
                height: 10px;
                width: 10px;
                border-radius: 5px;
                margin-right: 5px;
            }
        }
    }

    .dayPilotWrapper {
        display: flex;
        align-items: center;
        // justify-content: center;
    }

    .calendarWrapper {
        flex-grow: 1;
        margin-left: 20px;
    }

    .calendar_default_corner {
        display: none;
    }

    .calendar_default_shadow {
        pointer-events: none;
    }

    .calendar_default_event_inner {
        opacity: 0.75;
    }

    .exportRow {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;

        button:not(:first-of-type) {
            margin-left: 15px;
        }

        button {
            height: 50px;
            width: 150px;
            background: $primary-color;
            color: white;
            border: none;
            cursor: pointer;
            border-radius: 16px;
            transition: all 0.25s;
        }

        button:hover {
            background: white;
            border: 1px solid $primary-color;
            color: $primary-color;
        }
    }
}