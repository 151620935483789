@import '../../../variables.scss';

.auctionModal {
    width: 500px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;

    header {
        position: relative;

        margin-bottom: 25px;

        h2 {
            font-weight: bold;
            font-size: 24px;
            margin: 0;
        }

        button {
            position: absolute;
            right: 0;
            top: 0px;
            background: $primary-color;
            color: white;
            border-radius: 8px;
            border: none;
            padding: 5px 10px;
            margin: 0;
            cursor: pointer;
        }

    }

    .winnerInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        font-size: 20px;
        font-weight: 800;
    }

    .buttonRow {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px dotted $inactive-color;

        button {
            position: flex;
            background: $primary-color;
            color: white;
            border-radius: 8px;
            border: none;
            padding: 5px 10px;
            margin: 10px;
            cursor: pointer;
        }

        .deleteButton {
            background: rgb(126, 8, 8);
            color: white;
            border-radius: 8px;
            padding: 5px 10px;
            outline: none;
            border: none;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.25s;
        }

    }

    .infoWrapper {
        display: flex;
        flex-direction: column;

        .info {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dotted black;
            padding: 5px 0;

            p:first-of-type {
                margin-right: 50px;
            }

            address {
                text-align: right;
            }

            button {
                position: flex;
                justify-content: center;
                background: $primary-color;
                color: white;
                border-radius: 8px;
                border: none;
                padding: 5px 10px;
                cursor: pointer;
            }
        }

        .contactInfo:last-child {
            border: none;
        }

        img {
            width: 200px;
            height: auto; // or 100%
        }
    }

    h2 {
        margin-top: 25px;
    }

    .imageContainer img {
        width: 200px;
        height: auto; // or 100%
    }

    .editContainer {
        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            border-bottom: 1px dotted $inactive-color;
        }

        .imgUrlsContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 0;
            border-bottom: 1px dotted $inactive-color;

            .urlRow {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 0;
            }

            .rejectButton {
                border-radius: 8px;
                color: white;
                background-color: red;
            }
        }
    }
}

.optionStatModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}