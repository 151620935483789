@import '../../../../../.././../variables.scss';

.optionElemSelectorModal {
    width: 1000px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;
    h2 {
        margin-bottom: 25px;
    }
    button {
        // border: none;
        outline: none;
        margin-bottom: 8px;
        height: 50px;
        border-radius: 8px;
        background-color: white;
        border: 1px solid $inactive-color;
        cursor: pointer;
        transition: all 0.25s;
    }
    button:hover {
        background-color: $primary-color;
        color: white;
        border: none;
    }
}

.optionElemSelectorModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}