@import '../../../../variables.scss';

.comboCardContainer {
    width: calc(50% - 12.5px);
    background-color: white;
    margin: 12.5px 0;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 10px;

    height: 175px;
    display: flex;
    justify-content: space-between;


    .info {
        width: calc(60% - 10px);
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
    }
}