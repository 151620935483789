@import '../../../../variables.scss';

.staffCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    padding: 10px;
    padding-right: 50px;
    margin-right: 75px;
    border-radius: 16px;
    position: relative;
    min-height: 75px;

    * {
        flex-grow: 1;
        flex-basis: 0;

        margin: 0 10px;
    }

    h3 {
        display: flex;
        align-items: center;

        input {
            border: none;
            outline: none;
            background-color: transparent;
            width: 50px;
            height: 50px;
            flex-basis: 50px;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 20px;
        }
    }

    p {
        // text-align: center;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        * {
            flex-basis: 0;
            flex-grow: 1;
        }

        span {
            text-align: center;
            letter-spacing: 2px;
            font-weight: bold;
        }

        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: $primary-color;
            border: 1px solid $primary-color;
            border-radius: 8px;
            font-weight: 700;
            padding: 5px 0;
            transition: all 0.25s;
            cursor: pointer;
        }

        .active {
            background: $primary-color;
            color: white;
        }

        input {
            text-align: center;
            border: none;
            border-bottom: 1px solid black;
            letter-spacing: 5px;
            outline: none;
        }
    }

    .actions {
        display: flex;

        button:not(:first-child) {
            margin-left: 2.5px;
        }

        button {
            height: 100%;
            padding: 5px 0;
            border: none;
            outline: none;
            background-color: transparent;
            border-radius: 8px;
            color: white;
            font-weight: 600;
            cursor: pointer;
        }

        .changePin {
            background: #EFCA48;
        }

        .confirm {
            background: #82C282;
        }

        .cancel {
            background: $attention-color;
        }

        // .changePin {}
    }

    .remove {
        position: absolute;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: transparent;
        color: $attention-color;
        font-weight: 900;
        font-size: 24px;
        height: 50px;
        width: 50px;
        cursor: pointer;
    }

    .add {
        position: absolute;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: transparent;
        color: lightgreen;
        font-weight: 900;
        font-size: 24px;
        height: 50px;
        width: 50px;
        cursor: pointer;
    }
}

.staffCard:not(:first-child) {
    margin-top: 20px;
}