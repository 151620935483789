@import '../../../../variables.scss';

.ingrLogCardOutOfStock {
    box-shadow: 0px 0px 5px rgb(155, 0, 0);
}

.ingrLogCardWarning {
    box-shadow: 0px 0px 5px rgb(223, 134, 0);
}

.ingrLogCardContainer {
    width: calc(50% - 12.5px);
    background-color: white;
    margin: 12.5px 0;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 10px;

    height: 175px;
    display: flex;
    justify-content: space-between;


    .info {
        width: calc(60% - 10px);
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }

        .currentStock {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .ingrLogRow {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
            background: $primary-color;
            color: white;
            outline: none;
            border: none;
            border-radius: 16px;
            height: 30px;
            width: 160px;
            cursor: pointer;
        }
    }
}