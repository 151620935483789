@import '../../variables.scss';

.tabbarContainer {
    color: white;
    width: $tabbar-width;
    background-color: $tabbar-background-color;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -$tabbar-width;
    transition: left $animation-time;
    // padding-top: $header-top-height;

    .tabbarHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tabbarHeaderName {
            margin-top: 25px;
            font-weight: 600;
            font-size: 1rem;
        }

        .tabbarHeaderInfo {
            margin-top: 8px;
            font-weight: 600;
            font-size: 0.875rem;
            color: $tabbar-header-info-color;
        }
    }

    .tabbarLinksContainer {
        margin-top: 50px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;

        h3 {
            padding-left: 20px;
            margin-bottom: 15px;
            font-size: 1rem;
            font-weight: 600;
            color: $primary-color;
        }

        .tabbarLink:not(:first-child) {
            margin-top: 5px;
        }

        .active {
            background: $tabbar-link-active-color;
        }

    }

    .logout {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        color: white;
        font-weight: 700;
        background: $primary-color;
        cursor: pointer;
    }
}

.open {
    left: 0;
}