@import '../../../variables.scss';

.optionEditorContainer {
    header {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        button {
            position: absolute;
            left: -50px;
            width: 40px;
            height: 40px;
            outline: none;
            border: none;
            border-radius: 8px;
            color: $primary-color;
            font-size: 24px;
            font-weight: bold;
            border: 1px solid $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .optionContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}