@import '../../../variables.scss';

.tabbarLink{
    cursor: pointer;
    border-radius: 8px;
    // background-color: #2F4858;
    height: 50px;
    display: flex;
    align-items: center;
    transition: background-color 0.25s;
    img{
        padding-left: 20px;
        margin-right: 20px;
    }
}
.tabbarLink:hover{
    background: $tabbar-link-active-color;
}
.subTab {
    padding: 0 50px;
    // justify-content: center;
}