@import '../../../variables.scss';

.galaDonationContainer {

    .createButton {
        display: flex;
        justify-content: center;
        margin: 10px;
        font-size: 20;
        font-weight: 300;

        button {
            position: flex;
            background: $primary-color;
            color: white;
            border-radius: 8px;
            border: none;
            padding: 5px 10px;
            margin: 10px;
            cursor: pointer;
        }
    }

    .attendeeStatus,
    .ticketType {
        margin-bottom: 20px;

        .row {
            display: flex;
            margin-top: 5px;

            button {
                flex-grow: 1;
                flex-basis: 0;
                background: white;
                outline: none;
                // border-radius: 8px;
                border: 2px solid $primary-color;
                padding: 8px;
                color: $primary-color;
                cursor: pointer;
                transition: all 0.25s;
                font-size: 15px;
            }

            button:first-of-type {
                border-top-left-radius: 8px;
                border: 2px solid $primary-color;
                border-bottom-left-radius: 8px;
            }

            button:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .active {
                background: $primary-color;
                color: white;
            }
        }
    }

    .headerRow {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        font-size: 15px;
        text-align: center;
        border-bottom: 2px solid $primary-color;

        // .status {
        //     width: 10%;
        // }

        // .ticketNum {
        //     width: 10%;
        // }

        // .ticketTier {
        //     width: 20%;
        // }

        // .name {
        //     width: 20%;
        // }

        // .contact {
        //     width: 25%;
        // }
        div {
            flex-grow: 1;
            flex-basis: 0;


        }
    }

    .attendeeRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: normal;
        font-size: 11px;
        text-align: center;
        border-bottom: 1px dotted black;
        padding: 5px 0;

        // .status {
        //     width: 10%;
        // }

        // .ticketNum {
        //     width: 10%;
        // }

        // .ticketTier {
        //     width: 20%;
        // }

        // .name {
        //     width: 20%;
        // }

        // .contact {
        //     width: 25%;
        // }
        div {
            flex-grow: 1;
            flex-basis: 0;

            button {
                width: 100%;
                background: $primary-color;
                color: white;
                border-radius: 8px;
                outline: none;
                border: none;
                cursor: pointer;
                padding: 5px 0;
            }
        }
    }

    .infoWrapper {
        display: flex;
        flex-direction: column;

        .info {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dotted black;
            padding: 5px 0;

            p:first-of-type {
                margin-right: 50px;
            }

            address {
                text-align: right;
            }
        }

        .contactInfo:last-child {
            border: none;
        }
    }

}