@import '../../../../variables.scss';

.addEditIngrAdjModal {
    width: 1000px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    max-height: 800px;
    overflow-y: auto;

    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 24px;
    }

    .row {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        align-content: center;

        .space {
            width: 16px;
            height: 1px;
        }
    }

    .inputBox {
        width: 100%;
        margin-bottom: 16px;

        h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #2F4858;
        }

        input,
        select {
            margin-top: 8px;
            width: 100%;
            border: 1px solid #B1B1B1;
            box-sizing: border-box;
            border-radius: 8px;
            outline: none;
            height: 50px;
            padding: 0 10px;
            background-color: white;
        }

        textarea {
            margin-top: 8px;
            width: 100%;
            border: 1px solid #B1B1B1;
            box-sizing: border-box;
            border-radius: 8px;
            outline: none;
            height: 100px;
            padding: 10px;
        }
    }

    .buttonContainer {
        width: 100%;
        position: relative;
        height: 50px;
        margin-top: 24px;

        .buttonBox {
            float: right;

            button {
                border: 1px solid $primary-color;
                outline: none;
                background-color: white;
                box-sizing: border-box;
                border-radius: 20px;
                height: 50px;
                width: 150px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: $primary-color;
                cursor: pointer;
            }

            .cancel {
                margin-right: 8px;
            }

            .confirm {
                background-color: $primary-color;
                color: white;
            }

            button:disabled {
                background: $inactive-color;
                border: none;
            }
        }
    }
}

.addEditIngrAdjModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}