@import '../../../../../variables.scss';

.addEditItemContainer {
    background: white;
    padding: 30px;
    border-radius: 16px;
    margin-top: 30px;
    .creationSelector {
        display: flex;
        padding: 200px 0;
        align-items: center;
        justify-content: center;
        button:not(:last-child) {
            margin-right: 25px;
        }
        button {
            height: 100px;
            width: 300px;
            color: $primary-color;
            border: 2px solid $primary-color;
            outline: none;
            background-color: transparent;
            border-radius: 16px;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 25px;
        align-items: flex-end;
        .inputBox {
            flex-basis: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 10px;
            }
            input[type='text'],
            input[type='number'],
            select {
                width: 100%;
                border: 1px solid #dcdcdc;
                background-color: white;
                border-radius: 8px;
                height: 50px;
                padding: 0 10px;
                appearance: none;
            }
            textarea {
                width: 100%;
                border: 1px solid #dcdcdc;
                border-radius: 8px;
                height: 150px;
                padding: 10px 10px;
            }
            .taxSelector > div {
                border-radius: 8px;
            }
            .itemSelector > div {
                border-radius: 8px;
            }
        }
        .half {
            width: calc(50% - 5px) !important;
        }
        .minMax {
            width: 50%;
            display: flex;
        }

        .space {
            width: 10px;
        }
    }

    .priceKey {
        margin-bottom: 25px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 10px;
        }
        .row {
            margin-bottom: 10px;
            .priceKeyInputBox {
                flex-basis: 1;
                width: 100%;
                display: flex;
                .priceKeyName {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #dcdcdc;
                    border-right: 0px;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                input {
                    width: 50%;
                    border: 1px solid #dcdcdc;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    height: 50px;
                    padding: 0 10px;
                }
            }
        }
    }
    .optionContainer {
        padding: 5px;
        background-color: rgba(190, 190, 190, 0.2);
        border-radius: 8px;
        margin-top: 8px;
        width: calc(100% - 30px);
        position: relative;
        .removeOption {
            width: 20px;
            height: 20px;
            background-color: $attention-color;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            position: absolute;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
        .optionElemList {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .addOptionElem {
                background: $primary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 50px;
                font-weight: bold;
            }
        }
    }
    .inputCheckBox {
        flex-direction: row !important;
        background-color: white;
        border: 1px solid #dcdcdc;
        height: 50px;
        display: flex;
        border-radius: 8px;
        align-items: center;
        padding: 0 10px;
        width: calc(50% - 5px);
        input,
        label {
            cursor: pointer;
        }
        label {
            width: 100%;
        }
        input {
            width: 20px;
            height: 20px;
        }
    }
    .miscWrapper {
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .buttonsWrapper {
        height: 40px;
        // margin-top: 15px;
        position: relative;
        .buttons {
            position: absolute;
            right: 0;
            button {
                height: 40px;
                width: 150px;
                outline: none;
                border: none;
                border-radius: 20px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                background: white;
                cursor: pointer;
            }
            .cancel {
                margin-right: 10px;
                border: 1px solid $primary-color;
                color: $primary-color;
            }
            .confirm {
                background-color: $primary-color;
                color: white;
            }
        }
    }
}
