@import '../../../../../.././../variables.scss';

.ingredientSelectorModal {
    width: 1000px;
    background-color: $base-color;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 50px;
    min-height: 400px;
    max-height: 800px;
    overflow-y: auto;

    h2 {
        margin-bottom: 25px;
    }

    .ingrElems {
        margin-top: 25px;

        .ingr {
            display: flex;
            width: 100%;
            position: relative;
            margin-bottom: 10px;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }

            .ingrName {
                display: flex;
                align-items: center;
                width: 50%;
                height: 50px;
                border: 1px solid $inactive-color;
                border-right: none;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;

            }

            input {
                width: 50%;
                border: 1px solid $inactive-color;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .ingrName,
            input {
                padding-left: 20px;
            }

            p {
                // width: 20px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
            }
        }
    }

    .buttonWrapper {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;

        button {
            // border: none;
            outline: none;
            margin-bottom: 8px;
            height: 50px;
            width: 200px;
            border-radius: 8px;
            background-color: $primary-color;
            color: white;
            cursor: pointer;
            transition: all 0.25s;
            border: none;
        }

        button:hover {
            background-color: white;
            color: $primary-color;
            border: 1px solid $primary-color;
        }
    }
}

.ingredientSelectorModalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}