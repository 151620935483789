@import '../variables.scss';

.tabbarOpen {
    margin-left: $tabbar-width;
    // width: calc(100% - #{$tabbar-width} - 2*#{$header-padding});
}

.dashboardContainer {
    transition: width, margin-left $animation-time;
    // margin-top: $header-height;
    padding: 0 $dashboard-padding-x;
    background: #E5E5E5;
    padding-top: $header-height;

    .dashboardScreen {
        margin-top: $dashboard-padding-y;
        padding-bottom: $dashboard-padding-y;

        .screenTitle {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 700;
            // margin-bottom: 30px;
        }
    }

    .languageSelect {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 99;
        height: 75px;
        display: flex;
        align-items: center;

        .langButton {
            background-color: transparent;
            z-index: 100;
            color: #3d5666;
            border: none;
            outline: none;
            margin: 10px;
            cursor: pointer;
        }

        margin-right: 10px;
    }
}

.floorplanOpen {
    overflow: hidden;
    height: calc(100vh - #{$header-height});
}