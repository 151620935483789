.galaTicketViewer {
    display: flex;
    // flex-wrap: wrap;

    .info {
        border: 1px solid black;
        display: flex;

        div {
            display: flex;
            justify-content: space-between;
        }
    }
}