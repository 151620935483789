@import '../../variables.scss';

.analyticsContainer {
    .analytics {
        margin-top: 32px;
        background: white;
        padding: 30px 35px;
        border-radius: 16px;
        display: flex;
        .calendarContainer {
            display: flex;
            flex-direction: column;
            * {
                border: none;
                color: #7D7D7D;
            }

            .dataType {
                width: 100%;
                display: flex;
                margin-bottom: 16px;
                button {
                    width: 50%;
                    height: 40px;
                    color: $primary-color;
                    border: 1px solid $primary-color;
                    background: transparent;
                    cursor: pointer;
                }
                button:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                button:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
                .active {
                    background: $primary-color;
                    color: white;
                }
            }

            abbr {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
            }
            .react-calendar__navigation {
                align-items: center;
            }
            .react-calendar__navigation__arrow {
                color: $primary-color;
                border: 0.5px solid #DCDCDC;
                border-radius: 8px;
                margin: 8px;
                width: 44px;
                height: 44px;
            }
            .react-calendar__month-view__days {
                button {
                    border-radius: 8px;
                    width: 44px;
                    height: 44px;
                }
                .react-calendar__tile--now {
                    background: #4fa5f480;
                    abbr {
                        color: white;
                    }
                }
            }
            .react-calendar__tile--active {
                background: $primary-color !important;
                abbr {
                    color: white !important;
                }
            }
        }
        .statsContainer {
            margin-left: 40px;
            flex-grow: 1;
            flex-direction: column;
            height: 100%;
            
            header {
                .sessionSelector {
                    padding: 11px 18px;
                    border-radius: 16px;
                    background: transparent;
                    border: 1px solid $primary-color;
                    color: $primary-color;
                    option {
                        width: 600px;
                    }
                }
                display: flex;
                justify-content: space-between;
                button:not(:last-child) {
                    margin-right: 10px;
                }
                button {
                    padding: 10px 20px;
                    border-radius: 20px;
                    outline: none;
                    border: none;
                    background: none;
                    cursor: pointer;
                }
                .active {
                    background: #EEF6FE;
                    color: $primary-color;
                }
            }
            .stats {
                margin-top: 24px;
            }
        }
    }
}