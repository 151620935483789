@import '../../../variables.scss';

.salesStatContainer {
    display: flex;
    flex-direction: column;
    .salesRow:not(:last-child) {
        margin-bottom: 8px;
    }
    .salesRow {
        display: flex;
        .salesCard:not(:last-child) {
            margin-right: 8px;
        }
        .salesCard {
            border-radius: 16px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 33px 0;
            border: 0.5px solid #DCDCDC;
            h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                margin-bottom: 8px;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
            }
        }
    }
}
