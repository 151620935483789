@import '../../../../variables.scss';

.menuButton {
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: white;
    margin-top: 16px;
    .storeName {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;
        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            margin: 0;
            padding: 0;
        }
        .backArrow {
            cursor: pointer;
            border: solid black;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 5px;
            transform: rotate(315deg);
            // -webkit-transform: rotate(135deg);
            position: absolute;
            right: 50px;
        }
    }
}