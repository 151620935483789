@import '../../../../../variables.scss';

.categoryContainer {
    background-color: white;
    padding: 10px 30px;
    border-radius: 25px;
    margin-right: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.categoryContainer.active {
    background: rgba(79, 165, 244, 0.1);
    outline: 2px solid #4FA5F4;
}