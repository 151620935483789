@import '../../../../variables.scss';

.itemViewerContainer {
    header {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        button {
            position: absolute;
            left: -50px;
            width: 40px;
            height: 40px;
            outline: none;
            border: none;
            border-radius: 8px;
            color: $primary-color;
            font-size: 24px;
            font-weight: bold;
            border: 1px solid $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    .itemViewerContent {
        margin-top: 30px;
        .categoryTabs {
            display: flex;
            .addCategory {
                background-color: transparent;
                border: 1px dashed $inactive-color;
                box-sizing: border-box;
                h2 {
                    color: $primary-color;
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }
        .itemContainer {
            .addItemCardContainer {
                cursor: pointer;
                width: 100%;
                border: 1px dashed $inactive-color;
                height: 175px;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h2 {
                    font-style: normal;
                    color: $primary-color;
                    font-size: 40px;
                }
                p {
                    font-weight: 600;
                    font-size: 16px;
                }
            }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}