@import '../../../variables.scss';

.inventoryStatsContainer {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-end;
    // height: 100vh;
    .chartTypeSelector {
        button:not(:last-child) {
            margin-right: 10px;
        }
        button {
            padding: 10px 20px;
            border-radius: 20px;
            outline: none;
            border: none;
            background: none;
            cursor: pointer;
        }
        .active {
            background: #EEF6FE;
            color: $primary-color;
        }
    }
    .chart {
        width: 100%;
        height: 100%;
        canvas {
            // height: 100% !important;
            // width: auto !important;
        }
    }
}